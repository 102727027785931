<!-- 修改密码 -->
<template>
  <div class="changePassword">
    <header>
      <Button size="small" type="warning" @click="$router.back()">
        <Icon type="ios-arrow-back" />
        返回
      </Button>
      <Divider type="vertical" />
      <p style="font-size:18px">{{this.$route.meta.title}}</p>
    </header>
    <section>
      <Form :model="formItem" :label-width="120">
        <FormItem label="原密码">
          <Input v-model="formItem.oldPassword" type="password" password placeholder="原密码" style="width:330px"></Input>
        </FormItem>

        <FormItem label="新密码">
          <Input v-model="formItem.newPassword" type="password" password placeholder="请输入新密码（至少为6个到10个数字或字符）" style="width:330px"></Input>
        </FormItem>

        <FormItem label="确认密码">
          <Input v-model="again" placeholder="再次输入设置的新密码" type="password" password style="width:330px"></Input>
        </FormItem>

        <FormItem style="text-align:center">
          <Button @click="$router.back()">取消</Button>
          <Button type="warning" style="margin-left: 20px" :loading="loading_login" @click="submit">提交</Button>
        </FormItem>
      </Form>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading_login: false,
      //再次确认的密码
      again: "",
      formItem: {}
    }
  },
  methods: {
    //提交
    submit() {
      let verify = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/
      //验证格式及不能为空
      if (verify.test(this.formItem.newPassword) && verify.test(this.again) && this.formItem.oldPassword) {



        if (this.formItem.newPassword == this.again) {
          this.loading_login = true,
            this.formItem.mobile = localStorage.getItem("firm_mobile")
          this.app('enterpriseOperators', 'modifyPassword', this.formItem).then(res => {
            console.log(res);
            this.$Modal.warning({
              title: res.message
            })
            this.loading_login = false

          })
        } else {
          this.$Modal.warning({
            title: "请输入相同密码！"
          })
        }
      } else {
        this.$Modal.warning({
          title: "请输入正确密码格式及不能为空"
        })
      }
    }
  },
  created() {

  }
}
</script>

<style lang="less" scoped>
.changePassword {
  position: relative;
  height: 100%;
  background: #fff;
  padding: 30px;
  header {
    display: flex;
    align-items: center;
    border-bottom: 10px solid #f5f7f9;
    padding-bottom: 30px;
    .left {
      display: flex;
      align-items: center;
    }
  }
  section {
    width: 50%;
    margin: 0 auto;
    padding-top: 20px;
  }
}
</style>
